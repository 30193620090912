import { useState } from "react";
import { useHistory, useLocation } from "react-router";

export const Searchbar = () => {
    const location = useLocation();
    const history = useHistory();
    const params = new URLSearchParams(location.search);
    const urlTerm = params.get('termo') || '';

    const [term, setTerm] = useState(urlTerm);

    const submitSearch = (event) => {
        event.preventDefault();
        history.push({
            pathname: "/pesquisar",
            search: `?termo=${term}`,
        });
        document.getElementById('searchbar-wrapper').dispatchEvent(new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true
        }));
    };

    return (
        <form id="searchbar" className="searchbar" onSubmit={submitSearch}>
            <label htmlFor="search" className="screenreader">Pesquisar</label>
            <input id="search" className="searchbar-field" type="search" name="termo" placeholder="Pesquisar" value={term} onChange={(e) => setTerm(e.target.value)} />
            <button id="searchbar-submit" className="navbar-button" title="Pesquisar" type="submit">
                <i className="ph-magnifying-glass"></i>
            </button>
        </form>
    );
}