import { useEffect } from "react";
import { Link } from "react-router-dom";
import { default as pages } from "../api/pages.json";

export const TableOfContents = (props) => {
    useEffect(() => {
        let documentTitle = `Sumário · Pela Fresta da Porta, por Luiz Lopes`;
        document.title = documentTitle;
        document.querySelector('meta[property="og:title"]').setAttribute('content', documentTitle);
    });

    return (
        <>
            <main className="main">
                <div className="side">
                    <h1>Sumário</h1>
                </div>

                <div className="content">

                    <ul className="toc">
                        {pages.map(page => (page.type === 'page' ?
                            <li key={page.slug} className="toc-row">
                                <Link className="toc-link" to={`/${page.slug}`} title={page.title}>{page.title}</Link>
                                <span className="toc-dots"></span>
                                <span className="toc-page">{page.page}</span>
                            </li>
                            : ''))}
                    </ul>

                    <ul className="toc">
                        {pages.map(page => (page.type === 'post' ?
                            <li key={page.slug} className="toc-row">
                                <Link className="toc-link" to={`/${page.slug}`} title={page.title}>{page.title}</Link>
                                <span className="toc-dots"></span>
                                <span className="toc-page">{page.page}</span>
                            </li>
                            : ''))}
                    </ul>

                </div>
            </main>
        </>
    );
}