import { Navbrand } from "./Navbrand";

export const Navbar = ({ toggleSearchbar, toggleSidebar }) => {
    return (
        <nav className="navbar">
            <button id="sidebar-toggle" className="navbar-button" title="Menu" onClick={toggleSidebar}>
                <i className="ph-list"></i>
            </button>
            <Navbrand />
            <button id="searchbar-toggle" className="navbar-button" title="Pesquisar" onClick={toggleSearchbar}>
                <i className="ph-magnifying-glass"></i>
            </button>
        </nav>
    );
}