import { useEffect } from "react";
import ReactGA from 'react-ga';

export const Home = () => {
    useEffect(() => {
        let documentTitle = `Pela Fresta da Porta, por Luiz Lopes`;
        document.title = documentTitle;
        document.querySelector('meta[property="og:title"]').setAttribute('content', documentTitle);
    });

    ReactGA.pageview(window.location.pathname);

    return (
        <>
            <main className="main">

                <div className="side">

                    <h1>Pela Fresta da Porta</h1>

                    <figure className="author-figure">
                        <img className="author-image" src="/images/luiz-lopes.jpg" alt="Foto de Luiz Lopes" />
                        <figcaption className="author-figcaption">Luiz Lopes</figcaption>
                    </figure>

                </div>

                <article className="content">

                    <img className="author-image" src="/images/kirklai-CIUOQIsEUSI-unsplash.jpg" alt="Pessoa olhando pela fresta da porta" />

                    <p>
                        Pela Fresta da Porta é um livro póstumo de poesias escritas em vida por Luiz Lopes, reunidas e publicadas <em>in memoriam</em> em tributo à sua passagem.
                    </p>

                    <p>
                        Luiz foi um grande pai, avô e marido, batalhador, íntegro, que conquistou seu espaço com muito suor e lágrimas e deixou grandes exemplos.
                    </p>

                    <p>
                        De pais nordestinos e nascido em São Paulo, experimentou a escassez e a pobreza ainda na infância, tendo trabalhado desde a mais tenra idade e vivido em colégio interno.
                    </p>

                    <p>
                        Quando adulto, construiu família e fez uso do estudo e da educação para lograr melhores condições de vida.
                    </p>

                    <p>
                        Não satisfeito com o que a vida havia lhe reservado até então, estudou e formou-se no segundo grau aos 35 anos.
                    </p>

                    <p>
                        Graduou-se bacharel e seguiu sua busca através da educação até ser aprovado em inúmeros concursos e formar-se mestre por uma renomada universidade pública.
                    </p>

                    <p>
                        Alcançou a sonhada aposentadoria após anos de muita dedicação e pôde, enfim, desfrutar do melhor da vida.
                    </p>

                    <p>
                        Já na melhor idade, tomou gosto pelo verso e transformou suas ideias, amores alegrias e frustrações em crônicas e poemas.
                    </p>

                    <p>
                        Luiz viveu feliz seus últimos anos, fumando charuto, cachimbo e tomando vinho, viajando para novos lugares e reunindo queridos, escrevendo, tocando violão e torcendo pelo Flamengo. Enfim, aproveitando o que conquistou e a doce família que formou.
                    </p>

                    <p>
                        Partiu jovem, aos 67 anos, de infarto fulminante, bem nos braços de sua amada, deixando carentes ela, dois orgulhosos filhos, queridos netos, irmão e muitos amigos.
                    </p>

                </article>
            </main>
        </>
    )
}