import { Footer } from './components/Footer';
import { Navbar } from './components/Navbar';
import { Searchbar } from './components/Searchbar';
import { Sidebar } from './components/Sidebar';
import { Routes } from './Routes';

function App() {
    const toggleSidebar = ({ nativeEvent }) => {
        if (!nativeEvent.path.includes(document.getElementById('sidebar'))) {
            document.body.classList.toggle('sidebar-open');
        }
    };

    const toggleSearchbar = ({ nativeEvent }) => {
        const searchbar = document.getElementById('searchbar');
        if (!nativeEvent.path.includes(searchbar)) {
            document.body.classList.toggle('searchbar-open');
        }
        if (document.body.classList.contains('searchbar-open')) {
            window.setTimeout(() => searchbar.children.item(1).focus(), 100);
        }
    };

    return (
        <>
            <div className="wrapper">
                <Navbar toggleSidebar={toggleSidebar} toggleSearchbar={toggleSearchbar} />
                <Routes />
                <Footer />
            </div>
            <div id="sidebar-wrapper" className="sidebar-wrapper" onClick={toggleSidebar}>
                <Sidebar />
            </div>
            <div id="searchbar-wrapper" className="searchbar-wrapper" onClick={toggleSearchbar}>
                <Searchbar />
            </div>
        </>
    );
}

export default App;
