import { Link } from "react-router-dom";
import { Navbrand } from "./Navbrand";
import { default as pages } from "../api/pages.json";

const closeSidebar = () => {
    document.body.classList.remove('sidebar-open');
};

export const Sidebar = () => (
    <aside id="sidebar" className="sidebar">
        <Navbrand />
        <nav className="sidebar-nav">
            <Link className="sidebar-nav-item" to="/" title="Página inicial">Página inicial</Link>
            {pages.map(page => (page.type === 'page' ?
                <Link key={page.slug} className="sidebar-nav-item" to={`/${page.slug}`} title={page.title}>{page.title}</Link>
                : ''))}
        </nav>
        <button id="sidebar-close" className="navbar-button sidebar-close" title="Fechar menu" onClick={closeSidebar}>
            <i className="ph-x"></i>
        </button>
    </aside>
);