import { useEffect } from "react";
import { Link } from "react-router-dom";
import { default as pages } from "../api/pages.json";

export const Search = (props) => {
    const params = new URLSearchParams(props.location.search);
    const term = params.get('termo');
    let normalizedTerm = '';
    let result = [];

    if (term) {
        normalizedTerm = term.normalize("NFD").replace(/[^\w\s]/g, '');
        const regex = new RegExp(normalizedTerm.replace(/\s/g, '|'), 'gi');

        result = pages.reduce((result, page) => {
            const matchTitle = page.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").match(regex),
                matchContent = page.content.normalize("NFD").replace(/[\u0300-\u036f]/g, "").match(regex),
                titleMatches = matchTitle ? matchTitle.length : 0,
                contentMatches = matchContent ? matchContent.length : 0;

            if (matchTitle || matchContent) {
                return [
                    ...result,
                    {
                        ...page,
                        titleMatches: titleMatches,
                        contentMatches: contentMatches,
                        score: titleMatches * 10 + contentMatches
                    }
                ];
            }
            return result;
        }, []).sort((previous, current) => current.score - previous.score);
    }

    useEffect(() => {
        let documentTitle = `Resultados da sua busca por “${normalizedTerm}” · Pela Fresta da Porta, por Luiz Lopes`;
        document.title = documentTitle;
        document.querySelector('meta[property="og:title"]').setAttribute('content', documentTitle);
    });

    return (
        <>
            <main className="main">
                <div className="side">
                    <h1>Pesquisar</h1>
                </div>

                <div className="content">

                    <h4>Resultados da sua busca por “{normalizedTerm}”</h4>

                    {result.length ? (
                        <ul className="toc">
                            {result.map(page => (
                                <li key={page.slug} className="toc-row">
                                    <Link className="toc-link" to={`/${page.slug}`} title={page.title}>{page.title}</Link>
                                    <span className="toc-dots"></span>
                                    <span className="toc-page">{page.page}</span>
                                </li>
                            ))}
                        </ul>
                    ) : <p>Nada foi encontrado.</p>}

                </div>
            </main>
        </>
    );
}