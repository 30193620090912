import { Link, useRouteMatch } from "react-router-dom";
import { default as pages } from "../api/pages.json";

export const Footer = () => {
    const isPaginated = useRouteMatch("/:slug");
    const pageKey = isPaginated ? pages.indexOf(pages.find(page => page.slug === isPaginated.params.slug)) : null;
    const [previous, next] = isPaginated ? [pages[pageKey - 1], pages[pageKey + 1]] : [];

    return (
        <footer className={(!isPaginated ? 'home ' : '') + 'footer'}>

            {previous ?
                (<Link to={`/${previous.slug}`} className="footer-nav" title={`Página anterior: ${previous.title}`}>
                    <i className="ph-arrow-left"></i>
                    <div>
                        <div className="footer-nav-subtitle">Anterior</div>
                        <div className="footer-nav-title">{previous.title}</div>
                    </div>
                </Link>) : ""}

            <div className="footer-menu">
                <Link to="/" title="Página inicial">Página inicial</Link>
                ·
                <Link to="/epigrafe" title="Começar a leitura pela epígrafe">Começar</Link>
                ·
                <Link to="/sumario" title="Sumário dos poemas">Sumário</Link>
            </div>

            {next ?
                (<Link to={`/${next.slug}`} className="footer-nav" title={`Próxima página: ${next.title}`}>
                    <div>
                        <div className="footer-nav-subtitle">Próximo</div>
                        <div className="footer-nav-title">{next.title}</div>
                    </div>
                    <i className="ph-arrow-right"></i>
                </Link>) : ""}
        </footer>
    );
}