import { Route } from "react-router";
import { Home } from "./views/Home";
import { TableOfContents } from "./views/TableOfContents";
import { Page } from "./views/Page";
import { Search } from "./views/Search";
import ReactGA from 'react-ga';

export const Routes = () => {
    return (
        <>
            <Route exact path="/" render={props => <Home {...props} />} />
            <Route path="/:slug" render={props => {
                ReactGA.pageview(window.location.pathname);

                switch (props.match.params.slug) {
                    case 'sumario':
                        return <TableOfContents {...props} />;
                    case 'pesquisar':
                        return <Search {...props} />;
                    default:
                        return <Page slug={props.match.params.slug} {...props} />;
                }
            }} />
        </>
    )
}