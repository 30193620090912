import { useEffect } from "react";
import { Link } from "react-router-dom";
import { default as pages } from "../api/pages.json";

export const Page = (props) => {
    let pubDate, formattedPubDate = '';

    const page = pages.find(page => page.slug === props.slug);

    if (page.type === 'post') {
        pubDate = new Date(page.date);
        formattedPubDate = `${pubDate.getUTCDate().toString().padStart(2, '0')} · ${pubDate.toLocaleString('pt-BR', { month: 'long', timeZone: 'UTC' })} · ${pubDate.getUTCFullYear()}`;
    }

    useEffect(() => {
        let documentTitle = `${page.title} · Pela Fresta da Porta, por Luiz Lopes`;
        document.title = documentTitle;
        document.querySelector('meta[property="og:title"]').setAttribute('content', documentTitle);
    });

    /**
     * Formats a content converting newlines to <br> and double newlines to <p>.
     * 
     * The function first splits the content to an array of paragraphs, then removes the empty paragraphs,
     * wraps each one with <p> tags, joins them to form a string and converts the rest of the newlines to
     * <br> tags.
     * 
     * @param {String} content the raw content to format
     * @returns {String} the formatted content with HTML <p> and <br> tags
     */
    const formatContent = (content) => {
        return content
            .split(/(\r\n|\n|\r)(\s*)(\r\n|\n|\r)/)
            .filter(paragraph => !['', '\r\n', '\n', '\r'].includes(paragraph))
            .map(paragraph => `<p>${paragraph}</p>`)
            .join('')
            .replace(/(\r\n|\n|\r)/gm, '<br>');
    }

    return (
        <>
            <main className="main">
                <div className={page.hiddenTitle ? '' : 'side'}>
                    <h1 className={page.hiddenTitle ? 'screenreader' : ''}>{page.title}</h1>
                    {page.type === 'post' ?
                        <div className="meta">
                            <strong className="author">{page.author}</strong>
                            <time dateTime={`${pubDate.toISOString()}`} pubdate="true">{formattedPubDate}</time>
                            {page.categories.map(category => <Link key={category.slug} to={`/categoria/${category.slug}`} title={`Categoria ${category.name}`}>{category.name}</Link>)}
                        </div>
                        : ''}
                </div>

                <article className={'content' + (page.hiddenTitle ? ' width-100' : '')} dangerouslySetInnerHTML={{ __html: page.contentType === 'text' ? formatContent(page.content) : page.content }}>
                </article>
            </main>

            {page.endnotes ?
                <div className="main">
                    <div className="side"></div>
                    <div className="content-extra">
                        {page.endnotes}
                    </div>
                </div>
                : ''}
        </>
    );
}